import React, { useState } from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';
import ConsentForm from './ConsentForm';
import LogsEntryModal from './LogsEntryModal';
import PhasesCard from './PhasesCard';
import WeightStatsCard from './WeightStatsCard';
import WeightLossGraph from './WeightLossGraph';
import ProgressCircleChart from './ProgressCircleChart';
import AttachmentsModal from './AttachmentsModal';
import {
  FaFileAlt,
  FaWeight,
  FaFilePdf,
  FaClipboardList,
  FaChartLine,
} from 'react-icons/fa';

const DailyLogsFormModal = props => {
  const {
    clinicId,
    csrfToken,
    userWlProgram,
    refreshPatient,
    userActivePackage,
    dataLogRemaining,
    patient
  } = props;

  const {
    daily_change,
    last_day_logged,
    days_on_program,
    total_weight_loss,
    start_date
  } = userWlProgram;

  const [showAllLogs, setShowAllLogs] = useState(false);
  const [remainingLogsModal, setRemainingLogsModal] = useState(false);
  const [consentForm, setConsentForm] = useState(false);
  const [isGraphVisible, setIsGraphVisible] = useState(false);
  const [isCombineGraph, setIsCombineGraph] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);

  const handleShowAllLogs = () => setShowAllLogs(true);
  const handleCloseAllLogs = () => setShowAllLogs(false);
  const handleConsentForm = () => setConsentForm(true);
  const handleShowGraph = () => setIsGraphVisible(!isGraphVisible);
  const handleShowCombineGraph = () => setIsCombineGraph(!isCombineGraph);
  const handleShowPdfModal = () => setShowPdfModal(true);
  const handleClosePdfModal = () => setShowPdfModal(false);

  const date = new Date(start_date);
  const getActivePhase = () => {
    let sum = 0;
    const day = days_on_program;
    for (let i = 0; i < userWlProgram.weight_loss_program.phases.length; i++) {
      if (day <= sum + userWlProgram.weight_loss_program.phases[i].days) {
        return userWlProgram.weight_loss_program.phases[i].name;
      } else {
        sum += userWlProgram.weight_loss_program.phases[i].days;
      }
    }
  };

  const generateData = weightLossData => {
    if (!isGraphVisible) return;

    const startWeight = userWlProgram.start_weight; // Starting weight
    const targetWeight = patient.target_weight; // Final target weight
    const totalDays = weightLossData.daily_logs.length; // Total days logged

    const dailyLogs = [];
    const targetLogs = [];

    if (isCombineGraph) {
      weightLossData.user_wl_programs.forEach(program => {
        const programDays = program.daily_logs.length; // Number of logs in this program
        program.daily_logs.forEach((log, index) => {
          // Actual daily weight
          dailyLogs.push({
            x: new Date(log.date).toDateString(),
            y: log.weight
          });

          // Gradually decrease target weight
          targetLogs.push({
            x: new Date(log.date).toDateString(),
            y:
              startWeight - ((startWeight - targetWeight) / programDays) * index // Interpolated target weight
          });
        });
      });
    } else {
      weightLossData.daily_logs.forEach((log, index) => {
        // Actual daily weight
        dailyLogs.push({
          x: new Date(log.date).toDateString(),
          y: log.weight
        });

        // Gradually decrease target weight
        targetLogs.push({
          x: new Date(log.date).toDateString(),
          y: startWeight - ((startWeight - targetWeight) / totalDays) * index // Interpolated target weight
        });
      });
    }

    return [dailyLogs, targetLogs];
  };


  const graphData = generateData(isCombineGraph ? patient : userWlProgram);

  return (
    <div className='mt-3'>
      <Card className='shadow-sm'>
        <Card.Header className='d-flex justify-content-between align-items-center'>
          <h5 className='font-weight-bold text-primary mb-0'>
            {userWlProgram.weight_loss_program.name}
          </h5>
          <div className='d-flex flex-wrap'>
            {dataLogRemaining && (
              <Button
                variant='primary'
                className='mr-2 mb-2'
                onClick={() => setRemainingLogsModal(true)}
              >
                <FaWeight className='mr-1' /> Log Weight
              </Button>
            )}
            <Button
              variant='primary'
              className='mr-2 mb-2'
              onClick={handleShowAllLogs}
            >
              <FaClipboardList className='mr-1' /> View Logs
            </Button>
            <Button
              variant='primary'
              className='mr-2 mb-2'
              onClick={handleShowGraph}
            >
              <FaChartLine className='mr-1' />
              {isGraphVisible ? 'Hide Graph' : 'Show Graph'}
            </Button>
            {userWlProgram?.weight_loss_program?.pdf_files_urls?.length > 0 && (
              <Button
                variant='primary'
                className='mr-2 mb-2'
                onClick={handleShowPdfModal}
              >
                <FaFilePdf className='mr-1' /> View Attachments
              </Button>
            )}
            {userActivePackage?.package?.is_agreement_attached &&
              userActivePackage?.package?.user_accepted_agreement && (
                <Button
                  variant='primary'
                  className='mb-2'
                  onClick={handleConsentForm}
                >
                  <FaFileAlt className='mr-1' /> View Consent Form
                </Button>
              )}
          </div>
        </Card.Header>
        {isGraphVisible && (
          <>
            {patient.user_wl_programs.length > 1 &&
              patient.user_wl_programs[patient.user_wl_programs.length - 1]
                .id === userWlProgram.id && (
                <div className='d-flex pt-4 mb-4 pl-2'>
                  <Button
                    variant='primary'
                    className='mr-3'
                    onClick={handleShowCombineGraph}
                  >
                    {isCombineGraph
                      ? 'Hide Combine Graph'
                      : 'Show Combine Graph'}
                  </Button>
                </div>
              )}
            <div className='d-flex align-items-center flex-column flex-lg-row'>
              <div className='mr-md-4 weight-loss-graph weight-loss-chart'>
                <WeightLossGraph x={graphData[0]} y={graphData[1]} />
              </div>
              <span className='weight-loss-graph progressive-chart'>
                <ProgressCircleChart
                  progress={patient.goal_achieved ? patient.goal_achieved : 0}
                />
              </span>
            </div>
          </>
        )}
        <Card.Body>
          <Row className='mt-3'>
            <Col xs={12} sm={6} lg={4}>
              <WeightStatsCard
                weight={total_weight_loss}
                unit='lbs'
                text='Program Weight Loss'
                colorClass='primary'
              />
            </Col>
            <Col xs={12} sm={6} lg={4}>
              <WeightStatsCard
                weight={daily_change}
                unit='lbs'
                text='Daily Change'
                colorClass='primary'
              />
            </Col>
            <Col xs={12} sm={6} lg={4}>
              <WeightStatsCard
                weight={last_day_logged}
                unit=''
                text='Last Logged Day'
                colorClass='primary'
              />
            </Col>
            <Col xs={12} sm={6} lg={4}>
              <WeightStatsCard
                weight={date.toLocaleDateString('en-US')}
                unit=''
                text='Program Start Date'
                colorClass='primary'
              />
            </Col>
            <Col xs={12} sm={6} lg={4}>
              <WeightStatsCard
                weight={days_on_program}
                unit=''
                text='Days On Program'
                colorClass='primary'
              />
            </Col>
            <Col xs={12} sm={6} lg={4}>
              <WeightStatsCard
                weight={
                  (total_weight_loss / userWlProgram.daily_logs.length).toFixed(
                    2
                  ) ?? '--'
                }
                unit='lbs'
                text='Avg Daily Loss'
                colorClass='primary'
              />
            </Col>
            {getActivePhase() && (
              <Col xs={12} sm={6} lg={4}>
                <WeightStatsCard
                  weight={getActivePhase()}
                  unit=''
                  text='Current Phase'
                  colorClass='primary'
                />
              </Col>
            )}
          </Row>
          <p>Phases Instructions: </p>
          {userWlProgram?.weight_loss_program?.phases?.map(
            (phase, phaseIndex) => (
              <PhasesCard
                key={phaseIndex}
                phase={phase}
                phaseIndex={phaseIndex}
              />
            )
          )}
        </Card.Body>
      </Card>
      <LogsEntryModal
        clinicId={clinicId}
        csrfToken={csrfToken}
        handleClose={handleCloseAllLogs}
        show={remainingLogsModal}
        isAllLogs={showAllLogs}
        refreshPatient={refreshPatient}
        setShow={setRemainingLogsModal}
        userWlProgram={userWlProgram}
      />
      <ConsentForm
        show={consentForm}
        setShow={setConsentForm}
        userWlProgram={userActivePackage}
      />
      <AttachmentsModal
        show={showPdfModal}
        handleClose={handleClosePdfModal}
        pdfFiles={userWlProgram?.weight_loss_program?.pdf_files_urls || []}
      />
    </div>
  );
};

export default DailyLogsFormModal;
