import axios from 'axios';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { templateMessage } from '../../../../actions/weight_loss';
import { templateMessagesColumns } from '../../../../helpers/weight_loss';
import DataGridMUI from '../../../common/DataGridMUI';

const TemplateMessages = props => {
  const {
    csrfToken,
    clinicId,
    templateMessagesList,
    setTemplateMessagesList,
    handleMessageSend,
    istrue,
    setSelectedMessages,
    selectedMessages,
    handleAssignShow
  } = props;

  const [show, setShow] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [pageSize, setPageSize] = useState(10);

  const handleClose = () => {
    setShow(false);
    setTitle('');
    setMessage('');
    setSelectedMessage(null);
    setSelectedMessages([]);
  };

  const handleShow = () => setShow(true);

  const handleCreateOrUpdate = () => {
    let url;
    if (clinicId) {
      url = selectedMessage
        ? `/${clinicId}/template_messages/${selectedMessage}`
        : `/${clinicId}/template_messages`;
    } else {
      url = selectedMessage
        ? `/super_admin/update_message/${selectedMessage}`
        : '/super_admin/create_message';
    }

    templateMessage(
      { title, message },
      csrfToken,
      setTemplateMessagesList,
      selectedMessage ? axios.patch : axios.post,
      url
    );
    handleClose();
  };

  const handleSelectionChange = newSelection => {
    !clinicId && setSelectedMessages(newSelection);
  };

  return (
    <>
      <div className='card'>
        <div className='card-header d-flex flex-column align-items-start flex-md-row justify-content-md-between'>
          <h4 className='font-weight-bold ml-md-3 d-inline'>
            Custom Template Messages
          </h4>
          {istrue && (
            <Button
              variant='btn btn-primary float-right text-white'
              onClick={handleShow}
            >
              Create
            </Button>
          )}
          {selectedMessages?.length > 0 && (
            <Button
              variant='btn btn-secondary float-right text-white'
              onClick={handleAssignShow}
            >
              Assign
            </Button>
          )}
        </div>
        <div className='card-body'>
          <DataGridMUI
            handlePageSizeChange={setPageSize}
            pageSize={pageSize}
            selection={clinicId ? false : true}
            list={templateMessagesList}
            handleSelectionModelChange={handleSelectionChange}
            columns={templateMessagesColumns(
              handleMessageSend,
              setTitle,
              setMessage,
              setShow,
              csrfToken,
              setTemplateMessagesList,
              setSelectedMessage,
              istrue
            )}
          />
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size='sm'>
        <Modal.Header closeButton>
          <Modal.Title>Create Custom Template Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group'>
            <label>Title:</label>
            <input
              type='text'
              onChange={e => setTitle(e.target.value)}
              value={title}
              className='form-control'
              placeholder='Enter Title...'
            />
          </div>
          <div className='form-group'>
            <label>Message:</label>
            <textarea
              onChange={e => setMessage(e.target.value)}
              value={message}
              className='form-control'
              placeholder='Enter Message...'
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleCreateOrUpdate}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TemplateMessages;
