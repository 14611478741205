import axios from 'axios';
import { useState, useEffect } from 'react';
import TableBody from './TableBody';
import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography,
} from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Select from 'react-select';
import { Alert } from '@mui/material';
import { FitnessCenter, ArrowBack, CardMembership, Message } from '@material-ui/icons';
import CloseIcon from '@mui/icons-material/Close';
import {
  superAdminWeightLossTableColumns,
} from '../../helpers/weight_loss';
import DataGridMUI from '../common/DataGridMUI';
import WeightLossForm from '../weight_loss/components/Clinic/WeightLossForm';
import CampaignsList from '../campaigns/components/CampaignsList';
import CampaignNew from '../campaigns/CampaignNew';
import { AUTOMATED_CAMPAIGN, CUSTOM_CAMPAIGN } from '../../actions/actionTypes';
import { superAdminCampaignColumns } from '../../helpers/campaign';
import TemplateMessages from '../weight_loss/components/Clinic/TemplateMessages';

const ClinicsListTable = props => {
  const { clinics, csrfToken, weightLossProgramsList, campaigns } = props;
  const [clinicsList, setClinicList] = useState(clinics);
  const [assignClinicsList, setAssignClinicsList] = useState([]); // Separate state for assign modal clinics
  const [campaignsList, setCampaignsList] = useState(campaigns); // State for campaigns
  const [campaignModalOpen, setCampaignModalOpen] = useState(false);
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [campaignEmails, setCampaignEmails] = useState(null);
  const [activeTab, setActiveTab] = useState('campaigns'); // Default to 'campaigns' tab

  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [weightLossList, setWeightLossList] = useState(weightLossProgramsList);
  const [weightLossProgramData, setWeightLossProgramData] = useState(null);
  const [phases, setPhases] = useState([]);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [selectedWLPId, setSelectedWLPId] = useState(null);
  const [selectedClinicId, setSelectedClinicId] = useState('');
  const [templateMessagesList, setTemplateMessagesList] = useState([]); // State for template messages
  const [messageModalOpen, setMessageModalOpen] = useState(false); // State to control modal visibility
  const [selectedMessages, setSelectedMessages] = useState([]); // Track selected messages

  const fetchTemplateMessages = async () => {
    try {
      const response = await axios.get('/super_admin/messages_list', {
        headers: { 'X-CSRF-Token': csrfToken }
      });
      setTemplateMessagesList(response.data.template_messages);
    } catch (error) {
      console.error('Error fetching template messages:', error);
      setError('Failed to fetch template messages.');
    }
  };

  const handleOpenMessageModal = async () => {
    await fetchTemplateMessages();
    setSelectedMessages([]);
    setMessageModalOpen(true);
  };

  const handleCloseMessageModal = () => {
    setMessageModalOpen(false);
    setSelectedMessages([]);
  };

  const fetchWeightLossPrograms = async () => {
    try {
      const response = await axios.get(
        '/super_admin/weight_loss_program_list',
        {
          headers: { 'X-CSRF-Token': csrfToken }
        }
      );
      setWeightLossList(response.data.weightLossPrograms);
    } catch (error) {
      console.error('Error fetching weight loss programs:', error);
      setError('Failed to fetch weight loss programs.');
    }
  };

  const handleOnChange = async (event, clinic, index) => {
    let clinicState = { ...clinic, [event.target.name]: event.target.checked };
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    };

    try {
      const response = await axios.patch(
        `/${clinic.id}`,
        {
          scheduler_available: clinicState.scheduler_available,
          wlp_available: clinicState.wlp_available,
          initial_training_emails: clinicState.initial_training_emails
        },
        { headers }
      );
      let list = [...clinicsList];
      list[index] = clinicState;
      setClinicList(list);
      toastr.success('Clinic Updated');
    } catch (error) {
      setError(error.response?.data?.message || 'An error occurred');
      toastr.error(error.response?.data?.message || 'An error occurred');
    }
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleClose = () => {
    setIsCreating(false);
    setWeightLossProgramData(null);
    setCurrentCampaign(null);
    setPhases([]);
    setTimeout(() => fetchWeightLossPrograms(), 2000);
  };

  const duplicateWeightLossProgram = async wlpId => {
    try {
      const response = await axios.post(
        `/super_admin/duplicate_weight_loss_program/${wlpId}`,
        {},
        {
          headers: {
            'X-CSRF-Token': csrfToken,
            'Content-Type': 'application/json'
          }
        }
      );
      setWeightLossList(response.data.weightLossPrograms);
    } catch (error) {
      console.error('Error duplicating weight loss program:', error);
      alert('Failed to duplicate weight loss program.');
    }
  };

  const editWeightLossProgram = async programId => {
    try {
      const response = await axios.get(
        `/super_admin/edit_weight_loss_program/${programId}`,
        {
          headers: {
            'X-CSRF-Token': csrfToken,
            Accept: 'application/json'
          }
        }
      );
      const { weightLossProgram, phases } = response.data;
      setWeightLossProgramData(weightLossProgram);
      setPhases(phases);
      setIsCreating(true);
    } catch (error) {
      setError(
        error.response?.data?.message || 'Failed to load program for editing'
      );
    }
  };

  const editCampaign = async campaignId => {
    try {
      const response = await axios.get(
        `/super_admin/edit_campaign/${campaignId}`,
        {
          headers: {
            'X-CSRF-Token': csrfToken,
            Accept: 'application/json'
          }
        }
      );
      const { campaign, emails } = response.data;
      setCurrentCampaign(campaign);
      setCampaignEmails(emails);
      setActiveTab('dripCampaigns');
    } catch (error) {
      setError(
        error.response?.data?.message || 'Failed to load campaign for editing'
      );
    }
  };

  const handleOpenAssignModal = async wlpId => {
    try {
      const response = await axios.get('/super_admin/clinics_list', {
        headers: { 'X-CSRF-Token': csrfToken }
      });
      // Set assignClinicsList state specifically for the assign modal
      setAssignClinicsList(
        response.data.clinics.map(clinic => ({
          value: clinic.id,
          label: clinic.name
        }))
      );
      setMessageModalOpen(false)
      setSelectedWLPId(wlpId);
      setAssignModalOpen(true);
    } catch (error) {
      console.error('Error fetching clinics:', error);
    }
  };

  const handleCloseAssignModal = () => {
    setSelectedWLPId(null);
    setSelectedClinicId('');
    setAssignModalOpen(false);
  };

  const handleAssignClinic = async () => {
    try {
      const url = selectedWLPId
        ? `/super_admin/duplicate_weight_loss_program/${selectedWLPId}?clinic_id=${selectedClinicId}`
        : '/super_admin/assign_messages_to_clinic';

      const payload = selectedWLPId
        ? {} // No payload for WLPs as the ID is in the URL
        : {
            message_ids: selectedMessages,
            clinic_id: selectedClinicId
          };

      const response = await axios.post(url, payload, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json'
        }
      });

      if (selectedWLPId) {
        setWeightLossList(response.data.weightLossPrograms);
        toastr.success('WLP assigned successfully');
      } else {
        toastr.success('Messages assigned successfully');
      }

      handleCloseAssignModal();
    } catch (error) {
      console.error('Error assigning:', error);
      toastr.error('Failed to assign.');
    }
  };

  const columns = superAdminWeightLossTableColumns(
    setWeightLossList,
    csrfToken,
    null,
    duplicateWeightLossProgram,
    editWeightLossProgram,
    handleOpenAssignModal
  );

  useEffect(() => {
    if (campaignModalOpen && activeTab === 'campaigns') {
      const fetchCampaigns = async () => {
        try {
          const response = await axios.get(`/super_admin/campaigns`, {
            headers: { 'X-CSRF-Token': csrfToken }
          });
          setCampaignsList(response.data.campaigns);
          setCurrentCampaign(null);
          setCampaignEmails(null);
        } catch (error) {
          console.error('Error fetching campaigns:', error);
          setError('Failed to fetch campaigns.');
        }
      };
      fetchCampaigns();
    }
  }, [campaignModalOpen, activeTab]);

  const handleTabChange = tab => {
    setActiveTab(tab);
    if (tab === 'campaigns') {
      setCurrentCampaign(null);
      setCampaignEmails(null);
    }
  };

  return (
    <>
      <Button
        variant='outlined'
        color='primary'
        size='small'
        startIcon={<FitnessCenter />}
        onClick={handleOpenModal}
        style={{ margin: '10px', borderRadius: '8px', padding: '4px 10px' }}
      >
        Weight Loss Programs
      </Button>

      <Button
        variant='outlined'
        color='primary'
        size='small'
        startIcon={<CardMembership />}
        onClick={() => setCampaignModalOpen(true)}
        style={{ margin: '10px', borderRadius: '8px', padding: '4px 10px' }}
      >
        Campaigns
      </Button>

      <Button
        variant='outlined'
        color='primary'
        size='small'
        startIcon={<Message />}
        onClick={() => handleOpenMessageModal()}
        style={{ margin: '10px', borderRadius: '8px', padding: '4px 10px' }}
      >
        Messages
      </Button>

      <Dialog open={openModal} onClose={handleClose} fullWidth maxWidth='md'>
        <DialogTitle>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            {isCreating ? (
              <IconButton
                edge='start'
                onClick={() => setIsCreating(false)}
                aria-label='back'
              >
                <ArrowBack />
              </IconButton>
            ) : (
              <Button
                variant='contained'
                color='primary'
                onClick={() => setIsCreating(true)}
              >
                Create New Program
              </Button>
            )}
            <IconButton
              edge='end'
              color='inherit'
              onClick={() => setOpenModal(false)}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          {isCreating ? (
            <WeightLossForm
              campaignsList={campaignsList}
              csrfToken={csrfToken}
              weightLossProgram={weightLossProgramData}
              phases={phases}
              onClose={handleClose}
            />
          ) : (
            <DataGridMUI list={weightLossList} columns={columns} />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={assignModalOpen}
        onClose={handleCloseAssignModal}
        fullWidth
        maxWidth='xs'
      >
        <DialogContent>
          <Typography>Select a clinic to assign:</Typography>
          <Select
            options={assignClinicsList}
            onChange={selectedOption =>
              setSelectedClinicId(selectedOption?.value)
            }
            placeholder='Select a clinic'
            styles={{
              container: base => ({
                ...base,
                marginTop: '16px'
              }),
              menu: base => ({
                ...base,
                zIndex: 1300 // Adjust zIndex to ensure it appears above the modal
              }),
              menuPortal: base => ({
                ...base,
                zIndex: 1300
              })
            }}
            menuPortalTarget={document.body} // Renders menu in the body to avoid clipping
            menuShouldScrollIntoView={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAssignModal} color='secondary'>
            Cancel
          </Button>
          <Button
            onClick={handleAssignClinic}
            color='primary'
            variant='contained'
            disabled={
              !selectedClinicId || (!selectedMessages.length && !selectedWLPId)
            }
          >
            Assign
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={campaignModalOpen}
        onClose={() => setCampaignModalOpen(false)}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>
          <IconButton
            edge='end'
            color='inherit'
            style={{ float: 'right' }}
            onClick={() => setCampaignModalOpen(false)}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Tabs
              className='center-nav-tabs'
              activeKey={activeTab}
              onSelect={k => handleTabChange(k)}
              unmountOnExit
            >
              <Tab
                eventKey='campaigns'
                title='Campaigns'
                className='tab-content'
              >
                <CampaignsList
                  csrfToken={csrfToken}
                  campaigns={campaignsList}
                  columns={superAdminCampaignColumns(
                    setCampaignsList,
                    csrfToken,
                    editCampaign
                  )}
                />
              </Tab>
              <Tab
                eventKey='dripCampaigns'
                title='Informational campaigns'
                unmountOnExit
              >
                <CampaignNew
                  csrfToken={csrfToken}
                  campaignType={AUTOMATED_CAMPAIGN}
                  isSuperAdmin={true}
                  setActiveTab={setActiveTab}
                  currentCampaign={currentCampaign}
                  campaignEmails={campaignEmails}
                />
              </Tab>
            </Tabs>
          </Box>
        </DialogContent>
      </Dialog>

      <Modal
        show={messageModalOpen}
        onHide={handleCloseMessageModal}
        size='lg'
        className=''
      >
        <Modal.Body className='p-0'>
          <TemplateMessages
            csrfToken={csrfToken}
            handleMessageSend={message => console.log('Message sent:', message)} // Replace with actual send logic
            templateMessagesList={templateMessagesList}
            setTemplateMessagesList={setTemplateMessagesList}
            istrue={messageModalOpen}
            setSelectedMessages={setSelectedMessages}
            selectedMessages={selectedMessages}
            handleAssignShow={() => handleOpenAssignModal(null)}
          />
        </Modal.Body>
      </Modal>

      <TableContainer>
        {error && (
          <Alert severity='error' onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        <Table aria-label='clinics list table'>
          <TableHead>
            <TableRow>
              <TableCell>Clinic Id</TableCell>
              <TableCell>Clinic Name</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            clinicsList={clinicsList}
            handleOnChange={handleOnChange}
          />
        </Table>
      </TableContainer>
    </>
  );
};

export default ClinicsListTable;
