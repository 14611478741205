import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { AUTOMATED_CAMPAIGN, CUSTOM_CAMPAIGN } from '../../actions/actionTypes';
import { createCampaign, getEmailFiles } from '../../actions/campaign';
import { getEndDate, getStartDate } from '../../helpers/campaign';
import TitleCard from '../common/TitleCard.jsx';
import CampaignTemplate from './CampaignTemplate';
import axios from 'axios';

const CampaignNew = props => {
  const {
    csrfToken,
    redirect_url,
    clinicId,
    templatesList,
    campaignType,
    currentCampaign,
    campaignEmails,
    isSuperAdmin = false,
    setActiveTab = null
  } = props;
  const [date, setDate] = useState(startOfDay(new Date()));

  const [inputList, setInputList] = useState(
    campaignEmails?.length > 0
      ? campaignEmails
      : [
          {
            day: null,
            subject: null,
            body: null,
            send_date: campaignType == AUTOMATED_CAMPAIGN ? null : date,
            file: undefined
          }
        ]
  );

  useEffect(() => {
    if (!isSuperAdmin) {
      getEmailFiles(clinicId, currentCampaign.id, setInputList);
    }
  }, []);

  const [title, setTitle] = useState(currentCampaign?.title);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleInputChange = (e, index) => {
    const list = [...inputList];
    if (e.target?.name == 'file') {
      const { name } = e.target;
      list[index][name] = e.target.files[0];
    } else if (e.target) {
      const { name, value } = e.target;
      list[index][name] = value;
    } else {
      list[index]['body'] = e;
    }
    setInputList(list);
  };

  const handleDateChange = (e, index) => {
    const list = [...inputList];
    list[index]['send_date'] = e;
    setInputList(list);
  };

  const handleTemplateUse = (subject, body) => {
    const list = [...inputList];
    list[selectedIndex]['subject'] = subject;
    list[selectedIndex]['body'] = body;
    setInputList(list);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        day: null,
        subject: null,
        body: null,
        send_date: campaignType == AUTOMATED_CAMPAIGN ? null : date
      }
    ]);
  };

  const handleCreateCampaign = status => {
    for (var i = 0; i < inputList.length; i += 1) {
      if (inputList[i].file == null) {
        inputList[i].file = undefined;
      }
    }

    inputList.map((item, itemIndex) => (item.day = itemIndex + 1));
    const type =
      campaignType == AUTOMATED_CAMPAIGN ? AUTOMATED_CAMPAIGN : CUSTOM_CAMPAIGN;
    const todatlDays = inputList.length;
    const minDate = getStartDate(inputList);
    const maxDate = getEndDate(inputList);

    const basePath = isSuperAdmin ? '/super_admin' : `/${clinicId}`;

    const url = currentCampaign
      ? isSuperAdmin
        ? `${basePath}/update_campaign/${currentCampaign?.id}`
        : `${basePath}/campaigns/${currentCampaign?.id}`
      : isSuperAdmin
      ? `${basePath}/create_campaign`
      : `${basePath}/campaigns`;

    createCampaign(
      clinicId,
      csrfToken,
      redirect_url,
      inputList,
      title,
      type,
      todatlDays,
      minDate,
      maxDate,
      url,
      currentCampaign ? axios.put : axios.post,
      status,
      isSuperAdmin,
      setActiveTab
    );
  };

  return (
    <div className='layout-content'>
      <div className='container-fluid flex-grow-1 container-p-y'>
        <TitleCard
          title={title}
          setTitle={setTitle}
          placeholder='Enter campaign name....'
        />
        {inputList.map((x, i) => {
          return (
            <>
              <CampaignTemplate
                title={x.title}
                subject={x.subject}
                body={x.body}
                sendDate={x.send_date}
                index={i}
                campaignType={campaignType}
                setSelectedIndex={setSelectedIndex}
                templatesList={templatesList}
                handleRemoveClick={handleRemoveClick}
                inputList={inputList}
                handleTemplateUse={handleTemplateUse}
                handleDateChange={handleDateChange}
                handleChange={handleInputChange}
                setInputList={setInputList}
              />
              <div className='btn-box'>
                {inputList.length - 1 === i && (
                  <div className='float-right'>
                    <button
                      className='btn btn-primary mt-2'
                      onClick={handleAddClick}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add
                    </button>
                    {!isSuperAdmin && (
                      <button
                        className='btn btn-primary mt-2 ml-2'
                        onClick={() => handleCreateCampaign(false)}
                      >
                        Save Campaign
                      </button>
                    )}
                    <button
                      className='btn btn-primary mt-2 ml-2'
                      onClick={() => handleCreateCampaign(true)}
                    >
                      {currentCampaign && currentCampaign.status
                        ? 'Update'
                        : 'Create Campaign'}
                    </button>
                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default CampaignNew;
