import {
  Link as MuiLink,
  Switch,
  Button,
  Typography,
  FormControlLabel,
  Tooltip,
  TableRow,
  TableCell
} from '@material-ui/core';
import { ArrowForward, Add } from '@material-ui/icons';
import React from 'react';

const TableBody = ({ clinicsList, handleOnChange, addWLPToClinic }) => {
  return (
    <tbody>
      {clinicsList?.map((clinic, index) => (
        <TableRow
          key={clinic.id}
          hover
          style={{
            backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff',
            borderBottom: '1px solid #e0e0e0'
          }}
        >
          <TableCell style={{ padding: '8px 16px' }}>
            <Typography variant='body2' color='textSecondary'>
              {clinic.id}
            </Typography>
          </TableCell>
          <TableCell style={{ padding: '8px 16px' }}>
            <Typography variant='subtitle2' style={{ fontWeight: '500' }}>
              {clinic.name}
            </Typography>
          </TableCell>
          <TableCell style={{ padding: '8px 16px' }}>
            <Typography variant='body2' color='textSecondary'>
              {clinic?.owner?.name || 'N/A'}
            </Typography>
          </TableCell>
          <TableCell
            style={{
              padding: '8px 16px',
              gap: '12px',
            }}
          >
            {[
              {
                label: 'Weight Loss',
                name: 'wlp_available',
                checked: clinic.wlp_available
              },
              {
                label: 'Scheduler',
                name: 'scheduler_available',
                checked: clinic.scheduler_available
              },
              {
                label: 'Training Emails',
                name: 'initial_training_emails',
                checked: clinic.initial_training_emails
              }
            ].map(({ label, name, checked }) => (
              <Tooltip title={label} key={name} arrow>
                <FormControlLabel
                  control={
                    <Switch
                      checked={checked}
                      onChange={e => handleOnChange(e, clinic, index)}
                      name={name}
                      color='primary'
                      size='small'
                    />
                  }
                  label={
                    <Typography
                      variant='caption'
                      style={{ fontSize: '0.75rem', color: '#333' }}
                    >
                      {label}
                    </Typography>
                  }
                  style={{ marginRight: '12px' }}
                />
              </Tooltip>
            ))}
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              href={`/${clinic.id}`}
              endIcon={<ArrowForward />}
              style={{
                marginLeft: '5px',
                borderRadius: '8px',
                padding: '4px 10px'
              }}
            >
              Visit
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </tbody>
  );
};

export default TableBody;
