import axios from 'axios';

export const weightLossProgramApiCall = (
  weightLossProgram,
  phases,
  pdfFiles,
  csrfToken,
  clinicId,
  url,
  method,
  removePdf
) => {
  const headers = {
    Accept: 'application/json',
    'X-CSRF-Token': csrfToken
  };

  const formData = new FormData();
  formData.append('weight_loss_program', JSON.stringify(weightLossProgram));
  formData.append('phases', JSON.stringify(phases));
  pdfFiles.forEach((file, index) => {
    formData.append(`pdf_files[]`, file);
  });
  if (removePdf) {
    formData.append('remove_pdf', 'true');
  }

  method(url, formData, { headers })
    .then(({ data: { message } }) => {
      toastr.success(message);
      if(clinicId) {
        window.location.href = `/${clinicId}/configure`;
      }
    })
    .catch(e => {
      toastr.error(e.response.data.message);
    });
};


export const deleteWeightLossProgram = async (
  clinicId,
  weightLossProgramsId,
  setWeightLossPrograms,
  csrfToken
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };

  // Determine the URL based on whether clinicId is provided
  const url = clinicId
    ? `/${clinicId}/weight_loss_program/${weightLossProgramsId}`
    : `/super_admin/destroy_weight_loss_program/${weightLossProgramsId}`;

  axios
    .delete(url, { headers })
    .then(({ data: { weightLossPrograms, message } }) => {
      toastr.success(message);
      setWeightLossPrograms(weightLossPrograms);
    })
    .catch(e => {
      toastr.error(e.response?.data?.message || 'Failed to delete program.');
    });
};


export const deleteUserWeightLossProgram = (clinicId, setUserWLP, csrfToken, id) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  axios
    .delete(`/${clinicId}/user_wl_program/${id}`, { headers })
    .then(response => {
      setUserWLP([...response.data].reverse());
    })
    .catch(e => {
      toastr.error('Unable delete User WLP!');
    });
};

export const getUserWLP = (clinicId, setUserWLP, type) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  axios
    .get(`/${clinicId}/user_wl_program?type=${type}`, { headers })
    .then(response => {
      setUserWLP([...response.data].reverse());
    })
    .catch(e => {
      toastr.error('Unable to fetch data!');
    });
};

export const updateUserWLPStatus = (
  clinicId,
  userWLPId,
  setUserWeightLossList,
  csrfToken
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  axios
    .patch(
      `/${clinicId}/user_wl_program/${userWLPId}/update_status`,
      {},
      {
        headers
      }
    )
    .then(response => {
      setUserWeightLossList(response.data);
    })
    .catch(e => {
      toastr.error(e.response.data.message);
    });
};

export const getUserWLPStats = (clinicId, setUserWeightLossList, status) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  axios
    .get(`/${clinicId}/stats?status=${status}`, { headers })
    .then(response => {
      setUserWeightLossList(response.data);
    })
    .catch(e => {
      toastr.error('Unable to fetch data!');
    });
};

export const StartUserWLP = (
  user_wl_program,
  selectedPatient,
  csrfToken,
  setUserWLP,
  clinicId
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  axios
    .patch(
      `/${clinicId}/user_wl_program/${selectedPatient.id}`,
      user_wl_program,
      {
        headers
      }
    )
    .then(({ data }) => {
      setUserWLP([...data].reverse());
      toastr.success('User Weight Loss Program Updated!');
    })
    .catch(e => {
      toastr.error("weight loss programs can't have same start date");
    });
};

export const templateMessage = (
  message,
  csrfToken,
  setTemplateMessagesList,
  method,
  url
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };

  method(url, { template_message: message }, { headers })
    .then(({ data: { message, template_messages } }) => {
      toastr.success(message);
      setTemplateMessagesList(template_messages);
    })
    .catch(e => {
      toastr.error(e.response.data.message);
    });
};

export const getTemplateMessages = (clinicId, setTemplateMessagesList) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  axios
    .get(`/${clinicId}/template_messages`, { headers })
    .then(response => {
      setTemplateMessagesList(response.data);
    })
    .catch(e => {
      toastr.error('Unable to fetch data!');
    });
};

export const deleteTemplateMessage = async (
  clinicId,
  templateMessageId,
  setTemplateMessagesList,
  csrfToken
) => {
  debugger
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  let url = clinicId
    ? `/${clinicId}/template_messages/${templateMessageId}`
    : `/super_admin/destroy_message/${templateMessageId}`;
  axios
    .delete(url, {
      headers
    })
    .then(({ data: { message, template_messages } }) => {
      toastr.success(message);
      setTemplateMessagesList(template_messages);
    })
    .catch(e => {
      toastr.error(e.response.data.message);
    });
};

export const agreementSignCall = (
  weightLossProgram,
  csrfToken,
  sign,
  url,
  method,
  handleClose,
  agreementSignCall
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };

  method(
    url,
    {
      id: weightLossProgram.package.id,
      sign: sign,
      weight_loss_program_id: weightLossProgram?.program?.id
    },
    { headers }
  )
    .then(({ data: { message, program } }) => {
      toastr.success(message);
      agreementSignCall(program);
      handleClose();
    })
    .catch(e => {
      toastr.error(e.response.data.message);
    });
};
