import { DataGrid } from '@mui/x-data-grid';
import React from 'react';

const DataGridMUI = props => {
  const {
    list,
    columns,
    pageSize = 10,
    handlePageSizeChange = () => {},
    handleSelectionModelChange = () => {},
    selection = false
  } = props;

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={list}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={newPageSize => handlePageSizeChange(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection={selection}
        onSelectionModelChange={handleSelectionModelChange}
      />
    </div>
  );
};

export default DataGridMUI;
