import { makeStyles } from '@material-ui/core/styles';
import { GridActionsCellItem } from '@mui/x-data-grid';
import React from 'react';
import { Button } from 'react-bootstrap';
import { addDays } from 'date-fns';
import { deleteTemplateMessage, deleteWeightLossProgram } from '../actions/weight_loss';
import { Edit, FileCopy, Delete, AssignmentTurnedIn, Send } from '@material-ui/icons';

export const weightLosscampaignModalColumns = setCampaign => [
  { field: 'title', headerName: 'Title', flex: 1 },
  {
    field: 'days',
    headerName: 'Days',
    flex: 1
  }
];

export const UserWeightLossColumns = (
  setShow,
  setSelectedPatient,
  setDeleteRecordId,
  handleSureMoal
) => [
  { field: 'patient_name', headerName: 'Client', flex: 1 },
  {
    field: 'weight_loss_program_name',
    headerName: 'Weight Loss Program',
    flex: 1
  },
  {
    field: 'total_days',
    headerName: 'Program Length(days)',
    flex: 1
  },
  {
    headerName: 'Actions',
    field: 'actions',
    type: 'actions',
    width: 250,
    renderCell: params =>
      params.row.start_date ? (
        addDays(new Date(params?.row?.start_date), params?.row?.total_days) >=
          new Date() && (
          <>
            <Button
              variant='btn btn-sm-secondary btn-warning'
              size='small'
              onClick={() => {
                setShow(true);
                setSelectedPatient(params.row);
              }}
            >
              Edit
            </Button>
            <Button
              variant='btn btn-sm-secondary btn-danger ml-1'
              size='small'
              onClick={() => {
                setDeleteRecordId(params.row.id)
                handleSureMoal();
              }}
            >
              Delete
            </Button>
          </>
        )
      ) : (
        <>
          <Button
            variant='btn btn-sm-secondary btn-primary'
            size='small'
            onClick={() => {
              setShow(true);
              setSelectedPatient(params.row);
            }}
          >
            Start
          </Button>
          <Button
            variant='btn btn-sm-secondary btn-danger ml-1'
            size='small'
            onClick={() => {
              setDeleteRecordId(params.row.id);
              handleSureMoal();
            }}
          >
            Delete
          </Button>
        </>
      )
  }
];

export const superAdminWeightLossTableColumns = (
  setWeightLossPrograms,
  csrfToken,
  clinicId,
  duplicateWeightLossProgram,
  editWeightLossProgram,
  assignWLPToClinic
) => {
  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1
    },
    {
      field: 'is_public',
      headerName: 'Public',
      flex: 1,
      valueGetter: params => (params.value ? 'Yes' : 'No')
    },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: params => [
        <GridActionsCellItem
          icon={<Edit />}
          label='Edit'
          showInMenu
          onClick={() => {
            if (clinicId) {
              window.location.href = `/${clinicId}/weight_loss_program/${params.row.id}/edit`;
            } else {
              editWeightLossProgram(params.row.id);
            }
          }}
        />,
        <GridActionsCellItem
          icon={<FileCopy />}
          label='Duplicate'
          showInMenu
          onClick={() => {
            duplicateWeightLossProgram(params.row.id);
          }}
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label='Delete'
          showInMenu
          onClick={() => {
            deleteWeightLossProgram(
              params.row.clinic_id,
              params.row.id,
              setWeightLossPrograms,
              csrfToken
            );
          }}
        />,
        <GridActionsCellItem
          icon={<AssignmentTurnedIn />}
          label='Assign'
          showInMenu
          onClick={() => {
            assignWLPToClinic(params.row.id, clinicId); // Call assign function with WLP ID and clinic ID
          }}
        />
      ]
    }
  ];

  return columns;
};

export const weightLossTableColumns = (
  setWeightLossPrograms,
  csrfToken,
  clinicId,
  duplicateWeightLossProgram,
) => {
  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1
    },
    {
      field: 'is_public',
      headerName: 'Public',
      flex: 1,
      valueGetter: params => (params.value ? 'Yes' : 'No')
    },
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: params => [
        <GridActionsCellItem
          icon={<FileCopy />}
          label='Duplicate'
          showInMenu
          onClick={() => {
            duplicateWeightLossProgram(params.row.id);
          }}
        />,
        <GridActionsCellItem
          icon={<Edit />}
          label='Edit'
          showInMenu
          onClick={() => {
            window.location.href = `/${clinicId}/weight_loss_program/${params.row.id}/edit`;
          }}
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label='Delete'
          showInMenu
          onClick={() => {
            deleteWeightLossProgram(
              params.row.clinic_id,
              params.row.id,
              setWeightLossPrograms,
              csrfToken
            );
          }}
        />
      ]
    }
  ];

  if (clinicId) {
    columns.splice(2, 0, {
      field: 'campaign_id',
      headerName: 'Campaign Attached',
      flex: 1,
      valueGetter: params => (params.value ? 'Yes' : 'No')
    });
  }
  return columns;
};


export const disableAllSelection = makeStyles(theme => ({
  root: {
    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
    {
      display: 'none'
    }
  }
}));

export const userWLPDropDownOptions = [
  { value: '', label: 'All Programs' },
  { value: 'started', label: 'Started Programs' },
  { value: 'pending', label: 'Pending Programs' },
  { value: 'ended', label: 'Ended Programs' }
];

export const userWLPStatusDropDownOptions = [
  { value: 'not_reviewed', label: 'New Logs' },
  { value: 'reviewed', label: 'Reviewed Logs' }
];

export const templateMessagesColumns = (
  handleMessageSend,
  setTitle,
  setMessage,
  setShow,
  csrfToken,
  setTemplateMessagesList,
  setSelectedMessage,
  istrue

) => [
  { field: 'title', headerName: 'Title', flex: 1 },
  {
    headerName: 'Actions',
    field: 'actions',
    type: 'actions',
    width: 80,
    getActions: params => {
      const actions = [];

      if (istrue) {
        actions.push(
          <GridActionsCellItem
          icon={<Edit />}
          label='Edit'
          showInMenu
          onClick={() => {
            setShow(true);
            setSelectedMessage(params.row.id)
            setTitle(params.row.title);
            setMessage(params.row.message);
          }}
        />,
        <GridActionsCellItem
            icon={<Delete />}
            label='Delete'
            showInMenu
            onClick={() => {
              deleteTemplateMessage(
                params.row.clinic_id,
                params.row.id,
                setTemplateMessagesList,
                csrfToken
              );
            }}
          />
        );
      }
      else
      {
        actions.push(
          <GridActionsCellItem
            icon={<Send />}
            label='Send'
            showInMenu
            onClick={() => {
              handleMessageSend(params.row.message);
            }}
          />
        );
      }

      return actions;
    }
  }
];

export const convertDate = (dateString) => {
  var p = dateString.split(/\D/g)
  return [p[1], p[2], p[0]].join("-")
}

export const getDailyChange = (logs) => logs[logs?.length - 2]?.weight - logs[logs?.length - 1]?.weight

export const getCurrentPhase = (row, logDate) => {
  let phaseDate = row.start_date;
  logDate = new Date(logDate);

  for (let i = 0; i < row.phases.length; i++) {
    phaseDate = addDays(new Date(phaseDate), row.phases[i]?.days);
    if(logDate < phaseDate)
      return row.phases[i].name;
  }
}


export const getMealLogs = (log) => {
  const mealsLength = Object.keys(log.meals).length;
  if (mealsLength === 0) return '---';

  let str = '';
  for (const [key, value] of Object.entries(log.meals)) {
      str += value.reduce((acc, current) => acc = acc.concat(`${current}, `),"");
  }
  return str.slice(0, -2);
}

export const getSnackLogs = log => {
  const snacksLength = Object.keys(log.meals).length;
  if (snacksLength === 0) return '---';

  let str = '';
  for (const [key, value] of Object.entries(log.snacks)) {
      str += value.reduce((acc, current) => acc = acc.concat(`${current}, `), "");
  }
  return str.slice(0, -2);
}
