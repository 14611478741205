import axios from 'axios';
import React, { useState, useRef } from 'react';
import {
  Button,
  Collapse,
  Container,
  Row,
  Col,
  Form,
  Card
} from 'react-bootstrap';
import { weightLossProgramApiCall } from '../../../../actions/weight_loss';
import { weightLosscampaignModalColumns } from '../../../../helpers/weight_loss';
import CampaignTableCard from './CampaignTableCard';
import RadioOptions from '../RadioOptions';
import WeightLossEntryCard from '../WeightLossEntryCard';
import JoditEditor from 'jodit-react';

const WeightLossForm = props => {
  const editor = useRef(null);
  const {
    campaignsList,
    clinicId,
    csrfToken,
    weightLossProgram,
    phases,
    onClose
  } = props;

  const [title, setTitle] = useState(weightLossProgram?.name || '');
  const [agreement, setAgreement] = useState(
    weightLossProgram?.agreement || ''
  );
  const [isFormError, setIsFormError] = useState(false);
  const [isPhaseVisible, setIsPhaseVisible] = useState(phases?.length > 1);
  const [campaignCheck, setCampaignCheck] = useState(
    !!weightLossProgram?.campaign_id
  );
  const [publicCheck, setPublicCheck] = useState(
    !!weightLossProgram?.is_public
  );
  const [agreementCheck, setAgreementCheck] = useState(
    !!weightLossProgram?.agreement
  );
  const [campaignId, setCampaignId] = useState(
    weightLossProgram?.campaign_id ? [weightLossProgram?.campaign_id] : []
  );
  const [price, setPrice] = useState(weightLossProgram?.price || 0);
  const [numberOfPhases, setNumberOfPhases] = useState(phases?.length || 1);
  const [inputList, setInputList] = useState(
    phases?.length > 0
      ? phases
      : [
          {
            name: '',
            days: 1,
            meals: 0,
            snacks: 0,
            instructions: '',
            allowed_food: []
          }
        ]
  );
  const [pdfFiles, setPdfFiles] = useState([]);
  const [fileError, setFileError] = useState('');
  const [removePdf, setRemovePdf] = useState(false);
  const [pdfFileUrls, setPdfFileUrls] = useState(
    weightLossProgram?.pdf_files_urls || []
  );

  const handleInputChange = (e, index) => {
    const list = [...inputList];
    const { name, value } = e.target || { name: 'instructions', value: e };
    list[index][name] = value;
    setInputList(list);
  };

  const handleCampaignIdChange = selection => {
    setCampaignId(
      selection.length > 1
        ? selection.filter(s => !new Set(campaignId).has(s))
        : selection
    );
  };

  const handlePhaseCountChange = () => {
    const updatedList = Array.from({ length: numberOfPhases }, (v, i) => {
      return i < phases?.length
        ? phases[i]
        : {
            name: '',
            days: 1,
            meals: 0,
            snacks: 0,
            instructions: '',
            allowed_food: []
          };
    });
    setInputList(updatedList);
  };

  const handleFileChange = e => {
    const newFiles = Array.from(e.target.files).map(file => {
      const fileNameParts = file.name.split('.');
      const extension = fileNameParts.pop(); // Get the file extension
      const baseName = fileNameParts.join('.').replace(/[^a-zA-Z0-9]/g, '_'); // Replace special chars in the base name
      const newFileName = `${baseName}.${extension}`; // Reconstruct the file name
      const newFile = new File([file], newFileName, {
        type: file.type,
        lastModified: file.lastModified
      });
      return newFile;
    });

    const validFiles = newFiles.filter(
      file => file.type === 'application/pdf' && file.size <= 10 * 1024 * 1024
    );

    if (validFiles.length !== newFiles.length) {
      setFileError('All files must be PDFs and not exceed 10 MB.');
    } else {
      setFileError('');
    }

    setPdfFiles(prevFiles => [...prevFiles, ...validFiles]);
    setRemovePdf(false);
  };

  const handleRemovePdf = index => {
    const updatedFiles = [...pdfFiles];
    updatedFiles.splice(index, 1);
    setPdfFiles(updatedFiles);
  };

  const handleRemoveUploadedPdf = index => {
    const updatedUrls = [...pdfFileUrls];
    updatedUrls.splice(index, 1);
    setPdfFileUrls(updatedUrls);
  };

  const handleSubmit = async () => {
    // Validate form input
    if (
      title.trim() === '' ||
      price < 1 ||
      inputList.some(phase => phase.name.trim() === '')
    ) {
      setIsFormError(true);
      return;
    }

    // Prepare the data to send
    const weightLossProgramData = {
      name: title,
      campaign_id: campaignCheck ? campaignId[0] : null,
      agreement,
      is_public: publicCheck,
      price,
      pdf_file_urls: pdfFileUrls // Send updated PDF file URLs
    };

    // Determine if it's a SuperAdmin request (no clinicId)
    const isSuperAdmin = !clinicId;

    // Determine the URL and HTTP method
    const url = isSuperAdmin
      ? `/super_admin/${
          weightLossProgram
            ? `update_weight_loss_program/${weightLossProgram.id}`
            : 'create_weight_loss_program'
        }`
      : `/${clinicId}/weight_loss_program${
          weightLossProgram ? `/${weightLossProgram.id}` : ''
        }`;
    const httpMethod = weightLossProgram ? axios.put : axios.post;

    console.log(
      'Submitting weight loss program with data: ',
      weightLossProgramData,
      inputList,
      pdfFiles
    );

    // Perform the API call
    weightLossProgramApiCall(
      weightLossProgramData,
      inputList,
      pdfFiles,
      csrfToken,
      clinicId,
      url,
      isSuperAdmin && weightLossProgram ? axios.put : httpMethod, // Use PUT if updating as SuperAdmin, otherwise based on creation or update
      removePdf
    );

    // Close the form after submission
    if (isSuperAdmin) {
      onClose();
    }
  };

  return (
    <Container fluid>
      <Row className='justify-content-center'>
        <Col xs={12}>
          <div className='card'>
            <div className='card-body'>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>
                      Name:<span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                      className={
                        isFormError && title.trim() === ''
                          ? 'form-input-error'
                          : ''
                      }
                      placeholder='Enter Name...'
                    />
                    <Form.Text
                      className={
                        isFormError && title.trim() === ''
                          ? 'text-danger'
                          : 'text-muted'
                      }
                    >
                      {isFormError && title.trim() === ''
                        ? 'Name is required'
                        : 'Name should be greater than 5 characters'}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>
                      Price:<span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      type='number'
                      value={price}
                      onChange={e => setPrice(e.target.value)}
                      min={1}
                      step={1}
                      max={100000}
                      className={
                        isFormError && (!price || price < 1)
                          ? 'form-input-error'
                          : ''
                      }
                      placeholder='Enter Price...'
                    />
                    <Form.Text
                      className={
                        isFormError && (!price || price < 1)
                          ? 'text-danger'
                          : 'text-muted'
                      }
                    >
                      {isFormError && (!price || price < 1)
                        ? 'Price is required and must be greater than 0'
                        : 'Price must be greater than 0'}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <RadioOptions
                heading='Does the weight loss program contain multiple phases with different instructions?'
                radioCheck={isPhaseVisible}
                name='phase'
                setRadioCheck={setIsPhaseVisible}
              />
              {isPhaseVisible ? (
                <Collapse in={isPhaseVisible}>
                  <div>
                    <ul className='mt-3'>
                      <li className='mt-2'>
                        <div className='d-block'>
                          How many different phases are there in the weight loss
                          program?
                          <div className='d-flex mt-2'>
                            <Form.Control
                              type='number'
                              onChange={e => setNumberOfPhases(e.target.value)}
                              className='form-control w-auto'
                              value={numberOfPhases}
                              min={1}
                              max={100}
                              step={1}
                            />
                            <Button
                              className='ml-2'
                              onClick={handlePhaseCountChange}
                            >
                              Save
                            </Button>
                          </div>
                          <div className='mt-3'>
                            {inputList.map((data, index) => (
                              <WeightLossEntryCard
                                key={index}
                                data={data}
                                index={index}
                                handleInputChange={handleInputChange}
                                inputList={inputList}
                                setInputList={setInputList}
                                clinicId={clinicId}
                                isFormError={isFormError}
                              />
                            ))}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              ) : (
                <WeightLossEntryCard
                  data={inputList[0]}
                  hidePhaseNumer
                  index={0}
                  handleInputChange={handleInputChange}
                  inputList={inputList}
                  setInputList={setInputList}
                  clinicId={clinicId}
                  isFormError={isFormError}
                />
              )}
              <div className='mb-3'>
                <RadioOptions
                  heading='Would you like to assign a campaign to this weight loss program?'
                  radioCheck={campaignCheck}
                  name='campaign'
                  setRadioCheck={setCampaignCheck}
                />
                {campaignCheck && (
                  <CampaignTableCard
                    list={campaignsList}
                    handleCampaignId={handleCampaignIdChange}
                    selectedCampaign={campaignId}
                    title='Clinic Campaigns'
                    columns={weightLosscampaignModalColumns(setCampaignId)}
                  />
                )}
              </div>
              <div className='mb-3'>
                <RadioOptions
                  heading='Would you like to assign an informed consent to this weight loss program? (If yes, clients will be required to agree to the informed consent before they can set their goal weight or log their daily weight).'
                  radioCheck={agreementCheck}
                  name='agreement'
                  setRadioCheck={setAgreementCheck}
                />
                {agreementCheck && (
                  <div className='d-block mt-2'>
                    <JoditEditor
                      name='agreement'
                      ref={editor}
                      value={agreement}
                      tabIndex={1}
                      onBlur={newContent => setAgreement(newContent)}
                    />
                  </div>
                )}
              </div>
              <div className='mb-3'>
                <RadioOptions
                  heading='Would you like to make this weight loss program public?'
                  radioCheck={publicCheck}
                  name='is_public'
                  setRadioCheck={setPublicCheck}
                />
              </div>
              {pdfFileUrls.length > 0 && !removePdf && (
                <div className='mb-3'>
                  <Form.Label>Attached PDFs:</Form.Label>
                  {pdfFileUrls.map((url, index) => {
                    const fileName = url.split('/').pop().split('?').shift();
                    return (
                      <div
                        key={index}
                        className='d-flex justify-content-between align-items-center mb-2'
                      >
                        <a href={url} target='_blank' rel='noopener noreferrer'>
                          {fileName}
                        </a>
                        <Button
                          variant='danger'
                          onClick={() => handleRemoveUploadedPdf(index)}
                        >
                          Remove
                        </Button>
                      </div>
                    );
                  })}
                </div>
              )}
              <Form.Group>
                <Form.Control
                  type='file'
                  accept='application/pdf'
                  multiple
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  id='upload-pdfs'
                />
                <label htmlFor='upload-pdfs'>
                  <Button variant='primary' as='span'>
                    Upload PDFs
                  </Button>
                </label>
                {fileError && (
                  <Form.Text className='text-danger'>{fileError}</Form.Text>
                )}
              </Form.Group>
              {pdfFiles.length > 0 && (
                <Card className='mt-3'>
                  <Card.Body>
                    <Card.Title>PDFs:</Card.Title>
                    {pdfFiles.map((file, index) => (
                      <div
                        key={index}
                        className='d-flex justify-content-between align-items-center mb-2'
                      >
                        <span>{file.name}</span>
                        <Button
                          variant='danger'
                          onClick={() => handleRemovePdf(index)}
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              )}
              <Button className='mt-2 btn-primary' onClick={handleSubmit}>
                {weightLossProgram ? 'Update' : 'Create'}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WeightLossForm;
