import axios from 'axios';
import { AUTOMATED_CAMPAIGN } from './actionTypes';
import { serialize } from 'object-to-formdata';

export const createCampaign = (
  clinicId,
  csrfToken,
  redirect_url,
  inputList,
  title,
  type,
  totalDays,
  minDate,
  maxDate,
  url,
  method,
  status,
  isSuperAdmin,
  setActiveTab
) => {
  const campaign = {
    campaign: {
      clinic_id: clinicId,
      title: title,
      campaign_type: type,
      emails_attributes: inputList,
      days: totalDays,
      start_date: minDate,
      end_date: maxDate,
      status
    }
  };

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };

  method(url, serialize(campaign), {
    headers
  })
    .then(response => {
      status
        ? toastr.success(response.data.message)
        : toastr.success('Campaign Saved!');
      if (redirect_url) {
        window.location.href = redirect_url;
      }
      if (isSuperAdmin) {
        setTimeout(() => {
          setActiveTab('campaigns');
        }, 2000);
      }
    })
    .catch(e => {
      toastr.error(e.response.data.message);
    });
};

export const updateCampaign = async (
  clinicId,
  selectedRowsId,
  csrfToken,
  campaignId,
  campaignDates,
  handleClose
) => {
  const campaign = {
    clinic_id: clinicId,
    campaign_dates: campaignDates,
    user_ids: selectedRowsId
  };

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  await axios
    .post(`/${clinicId}/campaigns/${campaignId}/update_users`, campaign, {
      headers
    })
    .then(response => {
      toastr.success(response.data.message);
    })
    .catch(e => {
      toastr.error('Somthing went wrong!');
    });
  handleClose()
};

export const deleteCampaign = async (
  clinicId,
  campaignId,
  setCampaignList,
  csrfToken = null
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken
  };
  const url = clinicId
    ? `/${clinicId}/campaigns/${campaignId}`
    : `/super_admin/destroy_campaign/${campaignId}`;
  await axios
    .delete(url, { headers })
    .then(response => {
      toastr.success(response.data.message);
      setCampaignList(response.data.campaigns);
    })
    .catch(e => {
      toastr.error(
        e.response?.data?.message || 'An unexpected error occurred.'
      );
    });
};

export const getCampaignStats = (clinicId, campaignId, setUsers) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  axios
    .get(`/${clinicId}/campaigns/${campaignId}`, { headers })
    .then(response => {
      setUsers(response.data);
    })
    .catch(e => {
      toastr.error('Unable to fetch data!');
    });
};

export const getEmailFiles = (clinicId, campaignId, setInputList) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  axios
    .get(`/${clinicId}/campaigns/${campaignId}/email_data`, { headers })
    .then(response => {
      setInputList(response.data);
    })
    .catch(e => {
      toastr.error('Unable to fetch data!');
    });
};

export const getCampaignClients = (
  clinicId,
  campaignId,
  setUsers,
  setSelectedUsersIds,
  setCurrentCampaign,
  setIds
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  axios
    .get(`/${clinicId}/campaigns/${campaignId}/clients`, { headers })
    .then(response => {
      setUsers(response.data.users);
      setSelectedUsersIds(response.data.campaign_users_ids);
      setCurrentCampaign(response.data.campaign);
      setIds(response.data.campaign_users_ids);
    })
    .catch(e => {
      toastr.error('Unable to fetch data!');
    });
};

export const restartCampaign = (clinicId, campaignId, dates, selectedUsersIds, handleClose) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const campaignData = {
    userIds: selectedUsersIds,
    campaign_dates: dates,
  };
  axios
    .post(`/${clinicId}/campaigns/${campaignId}/restart`, campaignData, {
      headers
    })
    .then(response => {
      toastr.success(response.data.message);
      handleClose();
    })
    .catch(e => {
      toastr.error(e.response.data.message);
    });
};



export const getCampaignSpecificClients = (
  clinicId,
  campaignId,
  setUsers,
  setCurrentCampaign,
  setIds
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  axios
    .get(`/${clinicId}/campaigns/${campaignId}/campaign_clients`, { headers })
    .then(response => {
      setUsers(response.data.campaign_users);
      setCurrentCampaign(response.data.campaign);
      setIds(response.data.campaign_users_ids);
    })
    .catch(e => {
      toastr.error('Unable to fetch data!');
    });
};
